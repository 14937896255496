

let DOMAIN = 'localhost:3017'

if(process.env.NODE_ENV === 'production'){
  DOMAIN = 'api.swindly.com'
}

let API_URL, WS_URL
if(process.env.NODE_ENV === 'production'){
  API_URL = 'https://' + DOMAIN
  WS_URL = 'wss://' + DOMAIN
} 
else{
  API_URL = 'http://' + DOMAIN
  WS_URL = 'ws://' + DOMAIN
}

export {
  API_URL,
  WS_URL
}