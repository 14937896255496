import React from 'react'
import './index.css'


const physicalTypesMap = {
  device_switches: 'Switch'
}

export default function DeviceRow(props){
  const {
    numb,
    serial,
    loading,
    name,
    ownerName,
    error,
    physicalType
  } = props

  if(loading){
    return (
      <div className='device-row'>
        <div className='device-col'>
          Loading....
        </div>
      </div>
    )
  }

  if(error){
    return (
      <div className='device-row'>
        <div className='device-col'>
          Error: {error}
        </div>
      </div>
    )
  }

  const typeToHuman = physicalTypesMap[physicalType] || 'Type missing'

  return (
    <div className='device-row'>
      <div className='device-col device-col-numb'>
        {numb}.
      </div>
      <div className='device-col'>
        {name}
      </div>
      <div className='device-col'>
        {serial}
      </div>
      <div className='device-col'>
        {typeToHuman}
      </div>
      <div className='device-col'>
        {ownerName}
      </div>
    </div>
  )
}




