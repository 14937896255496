import {assocPath, reduce, mapObjIndexed} from 'ramda'


export default async function fetcher(...args){
  try{
    const resp = await fetch(...args)
    if(!resp.ok){
      throw resp
    }
    const json = await resp.json()
    resp.jsonResponse = json
    return resp
  }
  catch(e){
    if(process.env.NODE_ENV !== 'production'){
      console.log('api error', e)
    }

    try{
      const json = await e.json()
      e.jsonResponse = json
    }
    catch(e){

    }


    throw e
  }
}


export function createBuildablePipeline(...funcs){
  return (opts)=>{
    return reduce((built={}, func)=>{
      return func(built, opts)
    }, {}, funcs)
  }
}

export function toJson(built, opts={}){
  const {
    prepareJson,
  } = opts

  let body 

  if(prepareJson){
    body = JSON.stringify(prepareJson)
  }

  return {
    ...built,
    headers: {
      'Content-Type': 'application/json'
    },
    body
  }
}

export function toFormData(built, opts={}){
  let {
    prepareFormData,
    body
  } = opts

  if(prepareFormData){
    body = new FormData()
    mapObjIndexed((v, k)=>{
      body.append(k, v)
    }, prepareFormData)
  }
  return {
    ...built,
    headers: {
      'Content-Type': 'applicatio­n/x-www-form-urlencoded'
    },
    body
  }
}

export function toPost(built, opts){
  const method = opts.method || 'POST'
  return {
    ...built,
    method
  }
}

export function toGet(built, opts){
  return {
    ...built,
    method: 'GET'
  }
}

export function prepareJwt(built, opts={}){
  if(!opts.jwt){
    return built
  }
  return assocPath(['headers', 'Authorization'], `Bearer ${opts.jwt}`, built)
}






export const prepareJwtPostJsonPipeline = createBuildablePipeline(
  toJson,
  toPost,
  prepareJwt
)

export const prepareJwtGetJsonPipeline = createBuildablePipeline(
  toJson,
  toGet,
  prepareJwt
)