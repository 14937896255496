import React, {PureComponent} from 'react'
import withWsProvider from 'domains/Shared/WsContext/withWsProvider'
import withUserConsumer from 'domains/Shared/UserContext/withUserConsumer'
import {compose} from 'ramda'
class AuthedProviders extends PureComponent {

  render(){
    return (
      <AuthedComposed {...this.props}/>
    )
  }
}


const AuthedComposed = compose(
  withWsProvider
)((props)=>props.children)



export default withUserConsumer(AuthedProviders)