import React, {PureComponent} from 'react'
import {Provider} from 'domains/Shared/WsContext/Context'
import {WS_URL} from 'domains/Shared/utils/urls' 
import ReconnectingWebSocket from 'library/reconnecting-ws'
const {addSendJson, createParseJson} = require('domains/Shared/utils/jsonHelpers')


export default function withWsProvider(Child){
  return class WsProvider extends PureComponent{
    constructor(props){
      super(props)
      this._listeners = []
      this.state = {
        context: {
          ensureWs: this.ensureWs
        }
      }
    }


    componentDidMount(){
      // this.handleLifecycle()
    }


    ensureWs = ({suppressOpen}={})=>{
      if(this.ws){
        // we need to call open
        // for the new listener
        // even if the ws is already open
        let api
        if(!suppressOpen){
          setTimeout(()=>{
            if(this.isOpen){
              console.log('ws already open, calling listeners for:', api._listeners)
              api._listeners.forEach(([ce, cf])=>
                ce === 'open' ? cf() : undefined
              )
            }
          }, 5)
        }
        api = this.createWsApi(this.ws)
        return api
      }

      this.ws = new ReconnectingWebSocket(`${WS_URL}/ui`, undefined, {
        timeoutInterval: 2000
      })
      addSendJson(this.ws)

      this.ws.sendJsonWithJwt = (data={})=>{
        
        const headers = data.headers || {}
        data = {
          ...data,
          headers: {
            ...headers,
            jwt: this.props.jwt
          }
        }
        this.ws.sendJson(data)
      }


      this.ws.addEventListener('open', (...args)=>{
        this.isOpen = true
        this._callListeners('open', ...args)
      })

      // Listen for messages
      this.ws.addEventListener('message', createParseJson((...args)=>{
        this._callListeners('message', ...args)
      }, {fromEvent: true}))

      this.ws.addEventListener('close', (...args)=>{
        this.isOpen = false
        console.log('closing...')
        this._callListeners('close', ...args)
      })

      return this.createWsApi(this.ws)
    }

    _callListeners = (e, ...args)=>{
      this._listeners.forEach(([ce, cf])=>
        ce === e ? cf(...args) : undefined
      )
    }

    createOn = (api)=>(e, f)=>{
      const arr = [e, f]
      arr.api = api
      api._listeners.push(arr)
      this._listeners.push(arr)
    }

    createUnbindAll = (api)=>()=>{
      api._listeners = []
      this._listeners = this._listeners.filter((arr)=>
        api !== arr.api
      )
    }

    createOff = (api)=>(e, f)=>{
      api._listeners = api._listeners.filter(([ce, cf])=> ce !== e || cf !== f)
      this._listeners = this._listeners.filter(([ce, cf])=> ce !== e || cf !== f)
    }


    createWsApi = (ws)=>{
      const api = {
        _listeners: [],
        sendJsonWithJwt: ws.sendJsonWithJwt
      }

      api.on = this.createOn(api)
      api.off = this.createOff(api)
      api.unbindAll = this.createUnbindAll(api)
      return api
    }

    render(){
      return (
        <Provider value={this.state.context}>
          <Child {...this.props}/>
        </Provider>
      )
    }
  }
}