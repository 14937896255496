import React, { useState, useEffect, useMemo, useRef } from 'react'
import AddButton from 'components/Generic/AddButton'
import RouteLink from 'components/Generic/RouteLink'
import withUserConsumer from 'domains/Shared/UserContext/withUserConsumer'
import {compose} from 'ramda'
import { Route, Switch } from 'react-router-dom'
import CreateDeviceModal from './components/CreateDeviceModal'
import DeviceRow from './components/DeviceRow'
import uuid from 'library/uuid'
import {adminCreateDevice, adminGetDevices} from 'domains/DevicePortal/Shared/utils/fetchHelpers'
import Pagination from 'components/Generic/Pagination'
import './index.css'

import {
  update,
  findIndex,
  propEq,
  path
} from 'ramda'

function updateDeviceInList({
  id,
  data,
  devices,
}){
  const deviceIdx = findIndex(propEq('id', id), devices)
  let foundDevice =  devices[deviceIdx]
  foundDevice = {
    ...foundDevice,
    ...data
  }
  devices = update(deviceIdx, foundDevice, devices)

  return devices
}


function useDevicesFlow({
  jwt
}){
  const [state, setState] = useState({
    devices: [],
    pageIdx: 0,
    totalPerPage: 10,
    totalDevices: null
  })
  const ref = useRef()
  ref.current = state

  function setPaginationData({pageIdx, totalPerPage}){
    console.log('setting pagination data for:', pageIdx, totalPerPage)
    setState({...state, pageIdx, totalPerPage})
  }

  async function loadDevices({pageIdx, totalPerPage}){
    console.log('loading devices for:', pageIdx, totalPerPage)
    const {jsonResponse: res} = await adminGetDevices({
      pageIdx, 
      totalPerPage
    }, jwt)

    setState({
      ...state,
      devices: res.devices,
      totalDevices: res.total
    })
  }

  function loadDevicesFor({pageIdx=0, totalPerPage=10}){
    useEffect(()=>{
      setState({...state, pageIdx, totalPerPage})
      loadDevices({pageIdx, totalPerPage})
    }, [pageIdx, totalPerPage])
  }
  
  async function asyncCreateDevice(data){
    const device = {
      ...data,
      loading: true,
      id: uuid()
    }

    setState({
      ...state,
      devices: [
        device,
        ...state.devices, 
      ]
    })

    try{
      const {
        jsonResponse: {
          device: updatedDevice
        }
      } = await adminCreateDevice({
        device
      }, jwt)

      let devices = ref.current.devices

      devices = updateDeviceInList({
        id: device.id,
        data: {
          ...updatedDevice,
          loading: false,
          error: false,
        },
        devices
      })

      setState({
        ...ref.current,
        devices
      })

    }
    catch(error){
      let devices = ref.current.devices
      devices = updateDeviceInList({
        id: device.id,
        data: {
          loading: false,
          error: error.jsonResponse
        },
        devices
      })

      setState({
        ...ref.current,
        devices,
      })
    }
  }

  const isAsyncCreatingDevice = useMemo(()=>{
    return !!state.devices.filter((item)=>
      item.loading
    ).length
  }, [state.devices])

  

  return {
    isAsyncCreatingDevice,
    asyncCreateDevice,
    loadDevicesFor,
    setPaginationData,
    pageIdx: state.pageIdx,
    totalPerPage: state.totalPerPage,
    totalDevices: state.totalDevices,
    devices: state.devices
  }
}



function DevicesList(props){
  const {
    loadingDevices,
    jwt
  } = props

  const {
    isAsyncCreatingDevice,
    asyncCreateDevice,
    loadDevicesFor,
    setPaginationData,
    devices,
    pageIdx, 
    totalPerPage,
    totalDevices
  } = useDevicesFlow({
    jwt
  })
  const itemOffset = pageIdx * totalPerPage
  loadDevicesFor({pageIdx, totalPerPage})
  
  return (
    <div className='page page-tight'>
      <h1 className='main-header'>
        <span className='main-header-text'>
          All devices 
        </span>
        <span className='main-header-button-outer'>
          <RouteLink to='/app/admin/devices/create' component={AddButton}/>
        </span>
        {!!isAsyncCreatingDevice && 
          <span>
            (loading...)
          </span>

        }
      </h1>
      {loadingDevices ?
        <div>
          loading...
        </div>
      :
        devices && devices.length ?
        <div className='device-list-wrapper'>
          <div className='device-list-outer'>
            {devices.map((item, i)=>
              <DeviceRow 
                key={item.id}
                physicalType={item.physicalType}
                numb={(itemOffset || 0) + i + 1}
                serial={item.serial}
                loading={!!item.loading}
                name={item.name}
                ownerName={'Unknown'}
                error={path(['error', 'error'], item)}
              />
            )}
          </div>
          <Pagination
            pageIdx={pageIdx} 
            totalPerPage={totalPerPage}
            totalItems={totalDevices}
            onPaginationChange={setPaginationData}
          />
        </div>
      :
        <div>
          <p className='header-note'>
            Hmmm...no devices in the entire system yet.
            <br/>
            Get started by clicking the Plus button above.
          </p>
        </div>
      }

      <Switch>
        <Route path={`/app/admin/devices/create`} render={(props)=>
          <CreateDeviceModal {...props} onCreateDeviceClick={asyncCreateDevice}/>
        } />
      </Switch>
    </div>
  )
}


export default compose(
  withUserConsumer
)(DevicesList)


