import React, { PureComponent } from 'react'
import uuid from 'library/uuid'


export default function withDeviceSecret(Child){
  return class DeviceSecret extends PureComponent {
    constructor(props){
      super(props)
      this.state = {
        deviceSecret: uuid()
      }
    }





    render(){
      return (
        <Child
          {...this.props}
          deviceSecret={this.state.deviceSecret}
          />  
      )
    }
  }
}