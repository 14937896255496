import {createSelector} from 'reselect'
import {groupBy, toPairs, prop} from 'ramda'

export function makeSelectDevicesGroupedByPhysicalType(){
  return createSelector(
    ({devices})=>devices,
    (devices=[])=>{
      return toPairs(groupBy(prop('physicalType'), devices))
    }
  )
}