import React, { PureComponent } from 'react'


const withWizard = (opts={})=>(Child)=>{
  const getAsyncStepLogic = opts.makeGetAsyncStepLogic()
  const getAsyncStepComponent = opts.makeGetAsyncStepComponent()
  return class Wizard extends PureComponent {
    state = {
      logic: {},
      components: {},
      logicLoading: true,
      componentLoading: true
    }

    componentDidMount(){
      this.handleLifecycle()
    }

    componentDidUpdate(){
      this.handleLifecycle()
    }

    handleLifecycle(){
      const asyncLogicConfig = getAsyncStepLogic(this.props)
      const asyncComponentConfig = getAsyncStepComponent(this.props)
      if(this.state.logicLoading === asyncLogicConfig.key || this.state.componentLoading === asyncComponentConfig.key){
        return
      }
      Promise.all([
        this.getFromStateOrFetch('logic', asyncLogicConfig),
        this.getFromStateOrFetch('components', asyncComponentConfig),
      ]).then(([logicData, componentData])=>{
        const {exists: logicExists, data: logic} = logicData
        const  {exists: componentExists, data: component} = componentData
        if(!logicExists || !componentExists){
          let newState = this.state
          if(!logicExists){
            newState = {
              ...newState,
              logicLoading: false,
              logic: {
                ...this.state.logic,
                [asyncLogicConfig.key]: {
                  getNext: logic.makeGetNext && logic.makeGetNext(),
                  getPrev: logic.makeGetPrev && logic.makeGetPrev()
                }
              }
            }
          }
          if(!componentExists){
            newState = {
              ...newState,
              componentLoading: false,
              components: {
                ...this.state.components,
                [asyncComponentConfig.key]: component 
              }
            }
          }
          this.setState(newState)
        }
      })
    }


    getFromStateOrFetch = (stateKey, config)=>{
      return new Promise((resolve, reject)=>{
        const store = this.state[stateKey]
        const data = store[config.key]
        if(data){
          resolve({exists: true, data})
        }
        else{
          this.setState({
            [`${stateKey}Loading`]: config.key
          }, ()=>{
            config.fetch().then(({default: data})=>{
              resolve({exists: false, data})
            })
          })
        }
      })
    }

    renderStep = (props={})=>{
      const {
        componentLoading,
        // logicLoading,
        components
      } = this.state

      const {key} = getAsyncStepComponent(this.props)
      const Component = components[key]
      if(componentLoading || !Component){
        return null
      }
      
      
      
      return <Component {...props}/>
    }


    renderNextStepData = (props={})=>{
      const {
        logicLoading,
        logic
      } = this.state

      if(logicLoading){
        return null
      }

      const {key} = getAsyncStepLogic(this.props)
      const logicData = logic[key]

      return logicData && logicData.getNext && logicData.getNext(props)
    }

    renderPrevStepData = (props={})=>{
      const {
        logicLoading,
        logic
      } = this.state

      if(logicLoading){
        return null
      }

      const {key} = getAsyncStepLogic(this.props)
      const logicData = logic[key]
      
      return logicData && logicData.getPrev && logicData.getPrev(props)
    }

    render(){
      const {
        componentLoading,
        logicLoading
      } = this.state


      return (
        <Child
          {...this.props}
          componentLoading={componentLoading}
          logicLoading={logicLoading}
          renderStep={this.renderStep}
          renderNextStepData={this.renderNextStepData}
          renderPrevStepData={this.renderPrevStepData}
          />
      )
    }
  }
}



export default withWizard