
export function createParseJson(func, opts={}){
  return function(data){
    data = opts.fromEvent ? data.data : data
    data = JSON.parse(data)
    func(data)
  }
}

export function parseJson(data){
  return JSON.parse(data)
}


export function addSendJson(ws){
  ws.sendJson = function(json){
    let data = JSON.stringify(json)
    ws.send(data)
  }
}

