import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import {compose} from 'ramda'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
}



class HeaderBar extends PureComponent {
  onSignInClick = (e)=>{
    e.preventDefault()
    this.props.history.push('/sign-in')
  }

  onRegisterClick = (e)=>{
    e.preventDefault()
    this.props.history.push('/register')
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <IconButton className={classes.menuButton} color="inherit" aria-label="Menu">
              <MenuIcon />
            </IconButton>
            <Typography variant="title" color="inherit" className={classes.grow}>
              Swindly
            </Typography>
            <Button href="/sign-in" onClick={this.onSignInClick} color="inherit">Sign in</Button>
            <Button href="/register" onClick={this.onRegisterClick} color="inherit">Register</Button>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(HeaderBar)