import React, { PureComponent } from 'react'
import AddButton from 'components/Generic/AddButton'
import RouteLink from 'components/Generic/RouteLink'
import DevicesFetcher from './providers/DevicesFetcher'
import DevicePhysicalTypeGroup from './components/DevicePhysicalTypeGroup'
import withUserConsumer from 'domains/Shared/UserContext/withUserConsumer'
import {makeSelectDevicesGroupedByPhysicalType} from './selectors'
import {compose, path} from 'ramda'



class Dashboard extends PureComponent {
  constructor(props){
    super(props)
    this.selectDevicesGroupedByPhysicalType = makeSelectDevicesGroupedByPhysicalType()
  }
  renderDeviceGroup = ([groupName, devices])=>{
    if(!groupName){
      groupName = 'unknown'
    }

    return (
      <DevicePhysicalTypeGroup
        key={groupName}
        groupName={groupName}
        devices={devices}
      />
    )
  }

  renderLayout = ({devices, loadingDevices})=>{
    const deviceGroups = this.selectDevicesGroupedByPhysicalType({devices})
    return (
      <div className='page page-tight'>
        <h1 className='main-header'>
          <span className='main-header-text'>
            Your devices
          </span>
          <span className='main-header-button-outer'>
            <RouteLink prependNestedPath to='/device-setup/start' component={AddButton}/>
          </span>
        </h1>
        {loadingDevices ?
          <div>
            loading...
          </div>
        :
          devices && devices.length ?

          <div>
            {deviceGroups.map(this.renderDeviceGroup)}
          </div>
        :
          <div>
            <p className='header-note'>
              Hmmm...It doesn't look like you have any devices yet.
              <br/>
              Get started by clicking the Plus button above.
            </p>
          </div>
        

        }
      </div>
    )
  }

  render() {
    return (
      <DevicesFetcher 
        jwt={path(['props', 'jwt'], this)}
        userId={path(['props', 'user', 'id'], this)} 
        children={this.renderLayout}/>
    )
  }
}

export default compose(
  withUserConsumer
)(Dashboard)


