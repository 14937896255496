import React from 'react'
import ReactDOM from 'react-dom'
import './fonts.css'
import './globalStyles.css'
import './css/index.css'
import Main from 'domains/Shared/Main'
// import registerServiceWorker from './registerServiceWorker'

ReactDOM.render(<Main />, document.getElementById('root'));
// registerServiceWorker();
