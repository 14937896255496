import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'

class RouteLink extends PureComponent {
  onClick = (e)=>{
    e.preventDefault()
    let {
      to,
      history,
      match,
      prependNestedPath
    } = this.props

    if(prependNestedPath){
      if(to.pathname){
        to = {
          ...to,
          pathname: match.url + to.pathname
        }
      }
      else{
        to = match.url + to
      }
    }

    history.push(to)
  }

  render() {
    const {
      component: Comp,
      // prependNestedPath,
      componentProps,
      children
    } = this.props
    return (
      <Comp {...componentProps} children={children} onClick={this.onClick}/>
    )
  }
}

export default withRouter(RouteLink)
