import {API_URL} from 'domains/Shared/utils/urls'
import fetcher, {prepareJwtGetJsonPipeline, prepareJwtPostJsonPipeline} from 'library/fetcher'
// import {compose} from 'ramda'


export function getUserViaJwt(jwt){
  return fetcher(`${API_URL}/current-user`, prepareJwtGetJsonPipeline({jwt}))
}

export function storeSerial(data, jwt){
  return fetcher(`${API_URL}/devices/srl`, prepareJwtPostJsonPipeline({prepareJson: data, jwt}))
}

export function storeSecret(data, jwt){
  return fetcher(`${API_URL}/devices/scrt`, prepareJwtPostJsonPipeline({prepareJson: data, jwt}))
}

export function updateDevice(data, jwt){
  return fetcher(`${API_URL}/devices/${data.device.id}`, prepareJwtPostJsonPipeline({prepareJson: data, jwt, method: 'PATCH'}))
}


export function adminCreateDevice(data, jwt){
  return fetcher(`${API_URL}/devices`, prepareJwtPostJsonPipeline({prepareJson: data, jwt}))
}

export function adminGetDevices(data, jwt){
  return fetcher(`${API_URL}/devices/search`, prepareJwtPostJsonPipeline({prepareJson: data, jwt}))
}