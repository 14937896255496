import React, { PureComponent } from 'react'
import {  Route, Redirect } from 'react-router-dom'
import withUserConsumer from 'domains/Shared/UserContext/withUserConsumer'
import {compose} from 'ramda'

export class PermitAuthed extends PureComponent {
  renderComponent(){
    const { 
      render,
      userLoading
    } = this.props

    return render(userLoading)
  }

  renderRedirect(){
    const {
      location
    } = this.props

    return (
      <Redirect
        to={{
          pathname: "/sign-in",
          state: { from: location }
        }}
      />
    )
  }

  handleRouteRender = ()=>{
    const {
      jwt,
      userLoading
    } = this.props

    return jwt || userLoading ? this.renderComponent() : this.renderRedirect()
  }


  render() {
    const { 
      component: Component,
      ...rest 
    } = this.props

    return (
      <Route
        {...rest}
        render={this.handleRouteRender}
      />
    )
  }
}

export default compose(
  withUserConsumer
)(PermitAuthed)