import React, {PureComponent} from 'react'
import {Provider} from 'domains/Shared/UserContext/Context'
import localforage from 'localforage'
import {getUserViaJwt} from 'domains/DevicePortal/Shared/utils/fetchHelpers'

// const fakeUserData = {
//   id: '1213-232324-343434-3434'
// }

// const api = {
//   fetchUser(jwt){
//     return new Promise((resolve, reject)=>{
//       setTimeout(()=>{
//         resolve(fakeUserData)
//       }, 2000)
//     })
//   }
// }


export default function withUserProvider(Child){
  return class UserProvider extends PureComponent{
    constructor(props){
      super(props)
      this.state = {
        loadingJWT: true,
        context: {
          loading: true, 
          jwt: null,
          user: null,
          storeJwt: this.storeJwt,
          removeUserDataAndJwt: this.removeUserDataAndJwt
        }
      }
    }


    componentDidMount(){
      this.handleLifecycle()
    }


    removeUserDataAndJwt = async ()=>{
      await localforage.removeItem('jwt')
      this.setState({
        context: {
          ...this.state.context,
          user: null,
          jwt: null
        }
      })
    }

    async handleLifecycle(){
      this.setState({
        loadingJWT: true,
        context: {
          ...this.state.context,
          loading: true
        }
      })
      let jwt = await this.loadingTokenFromStorageOrState()
      // hardcode for now
      // jwt = 'adsfds-fdsfwerjlfkj-efdsflfkjfsflkdj'
      
      if(!jwt){
        this.setState({
          loadingJWT: false,
          context: {
            ...this.state.context,
            jwt,
            loading: false
          }
        })
      }

      if(!jwt){
        // if there is no jwt, we don't want to
        // auto fetch user
        return
      }

      let user
      try{
        user = await this.fetchUserViaJWT(jwt)
        this.setState({
          loadingJWT: false,
          context: {
            ...this.state.context,
            user,
            jwt,
            loading: false
          }
        })
      }
      catch(e){
        // remove JWT, it failed
        jwt = undefined
        await localforage.setItem('jwt', jwt)
      }

      this.setState({
        loadingJWT: false,
        context: {
          ...this.state.context,
          user,
          jwt,
          loading: false
        }
      })
    }

    async loadingTokenFromStorageOrState(){
      if(this.state.context.jwt){
        return this.state.context.jwt
      }
      else{
        return localforage.getItem('jwt')
      }
    }



    async fetchUserViaJWT(jwt){
      const data = await getUserViaJwt(jwt)
      return data.jsonResponse
    }

    storeJwt = async (jwt)=>{
      await localforage.setItem('jwt', jwt)

      await this.handleLifecycle()
    }

    render(){
      return (
        <Provider value={this.state.context}>
          <Child/>
        </Provider>
      )
    }
  }
}