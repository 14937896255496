import React, { PureComponent } from 'react'
// import uuid from 'library/uuid'


export default function withNewWifiCreds(Child){
  return class NewWifiCreds extends PureComponent {
    constructor(props){
      super(props)
      this.state = {
        wifiName: '',
        wifiPassword: ''
      }
    }

  onFieldChange = (key)=>{
    this.funcCache = this.funcCache || {}

    if(!this.funcCache[key]){
      this.funcCache[key] = (e)=>
        this.setState({[key]: e.target.value})
    }
    
    return this.funcCache[key]
  }



    render(){
      return (
        <Child
          {...this.props}
          {...this.state}
          onFieldChange={this.onFieldChange}
          />  
      )
    }
  }
}