import React, {PureComponent} from 'react'
import {Consumer} from 'domains/Shared/UserContext/Context'


export default function withUserConsumer(Child){
  return class withUserConsumer extends PureComponent{

    renderChild = (context)=>
      <Child 
        {...this.props}
        user={context.user}
        userLoading={context.loading}
        jwt={context.jwt}
        storeJwt={context.storeJwt}
        removeUserDataAndJwt={context.removeUserDataAndJwt}
      />


    render(){
      return (
        <Consumer>
          {this.renderChild}
        </Consumer>
      )
    }
  }
}