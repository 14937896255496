import React, { PureComponent } from 'react'
// import Button from '@material-ui/core/Button'
// import AddIcon from '@material-ui/icons/Add'
// import RouteLink from 'components/Generic/RouteLink'
import pascalCase from 'domains/Shared/utils/pascalCase'
import './index.css'

const physicalTypesPuralized = {
  device_switches: 'Switches',
  unknown: 'Unknown Devices'
}


class DevicePhysicalTypeGroup extends PureComponent {
  state = {}

  componentDidMount(){
    this.loadDevicePhysicalTypeTile()
  }

  loadDevicePhysicalTypeTile = async ()=>{
    const {
      groupName
    } = this.props
    const compGroupName = pascalCase(groupName)

    const comp = await import(`./components/${compGroupName}Tile`)
    this.setState({
      DeviceComponent: comp.default
    })
  }


  renderDevice = (device)=>{
    const {
      DeviceComponent
    } = this.state
    return (
      <div key={device.id} className='device-item-outer'>
        <DeviceComponent
          device={device}
        />
      </div>
    )
  }

  render() {
    const {
      groupName,
      devices
    } = this.props
    const groupPlural = physicalTypesPuralized[groupName] || groupName
    const {
      DeviceComponent
    } = this.state

    return (
      <div className='device-pt-outer'>
        <h2 className='device-pt-title'>
          {groupPlural}
        </h2>
        <div className='devices-pt-list'>
          { DeviceComponent ?
            devices.map(this.renderDevice)
          :
            'loading...'
          }
        </div>
      </div>
    )
  }
}

export default DevicePhysicalTypeGroup


