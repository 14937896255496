import {compose} from 'ramda'
import React, {PureComponent, Fragment} from 'react'
import withUserProvider from 'domains/Shared/UserContext/withUserProvider'
import withAppRouter from 'domains/Shared/withAppRouter'
import CssBaseline from '@material-ui/core/CssBaseline'

export class Main extends PureComponent{
  render(){
    return (
      <Fragment>
        <CssBaseline/>
        {this.props.children}
      </Fragment>
    )
  }
}

export default compose(
  withUserProvider,
  withAppRouter
)(Main)