import React, {PureComponent, Fragment} from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import MarketingHomePage from 'domains/Marketing/domains/Home'
import DashboardPage from 'domains/DevicePortal/domains/Dashboard'
import DeviceSetup from 'domains/DevicePortal/domains/DeviceSetup'
import AdminDevicesList from 'domains/DevicePortal/domains/Admin/DevicesList'
import SignInPage from 'domains/Marketing/domains/SignIn'
import RegisterPage from 'domains/Marketing/domains/Register'
import MarketingLayout from 'domains/Marketing/Shared/BaseLayout'
import DevicePortalLayout from 'domains/DevicePortal/Shared/BaseLayout'
import PermitAuthed from 'domains/Shared/PermitAuthed'
import SignedInPublicPathRedirector from 'domains/Marketing/Shared/SignedInPublicPathRedirector'
import AuthedProviders from 'domains/Shared/AuthedProviders'

export default function withAppRouter(Child){
  return class AppRouter extends PureComponent{
    
    renderMarketingLayout = ()=>
      <MarketingLayout>
        <Switch>
          <Route exact path="/" component={MarketingHomePage} />
          <SignedInPublicPathRedirector render={()=>
            <Fragment>
              <Route path="/sign-in" component={SignInPage} />
              <Route path="/register" component={RegisterPage} />
            </Fragment>
          }>
          </SignedInPublicPathRedirector>
        </Switch>
      </MarketingLayout>
    
    renderDevicePortalLayout = ({match})=>
      <PermitAuthed render={(loading)=>
        <AuthedProviders>
          <DevicePortalLayout userLoading={loading} render={()=>
            <Switch>
              <Route exact path={match.url} component={DashboardPage} />
              <Route path={`${match.url}/device-setup/:step`} component={DeviceSetup} />
              <Route path={`${match.url}/admin/devices`} component={AdminDevicesList} />
            </Switch>
          }/>
        </AuthedProviders>
      } />
      

    render(){
      return (
        <BrowserRouter>
          <Switch>
            <Route path="/app" render={this.renderDevicePortalLayout} />
            <Route path="/" render={this.renderMarketingLayout} />
          </Switch>
        </BrowserRouter>
      )
    }
  }
}