import React, { PureComponent, createContext } from 'react'
import withWsConsumer from 'domains/Shared/WsContext/withWsConsumer'
import {compose, prop} from 'ramda'
import mergeArraysBy from 'domains/Shared/utils/mergeArraysBy'
import updateMergeFlow from 'domains/Shared/utils/updateMergeFlow'
import {updateDevice} from 'domains/DevicePortal/Shared/utils/fetchHelpers'

const Context = createContext()



class DeviceFetcher extends PureComponent {
  constructor(props){
    super(props)

    this.state = {
      context: {
        loadingDevices: true,
        devices: [],
        updateDevice: this.updateDevice
      }
    }
  }

  componentDidMount(){
    this.fetchDevices()
  }

  componentWillUnmount(){
    this.ws.unbindAll()

  }

  updateDevice = async (device)=>{
    const devices = this.state.context.devices
    const deviceId = device.id
    let {
      updatedItem: updatedDevice, 
      items: updatedDevices
    } = updateMergeFlow(deviceId, {...device, loading: true}, devices)

    this.setState({
      context: {
        ...this.state.context,
        devices: updatedDevices
      }
    })

    const {
      jsonResponse: {
        device: deviceRes
      }
    } = await updateDevice({device: updatedDevice}, this.props.jwt)
    
    let {
      items: updatedDevicesFromRes
    } = updateMergeFlow(deviceId, {...deviceRes, loading: false}, updatedDevices)


    this.setState({
      context: {
        ...this.state.context,
        devices: updatedDevicesFromRes
      }
    })
  }

  fetchDevices = async ()=>{
    const {
      // jwt,
      userId,
      ensureWs
    } = this.props
    this.ws = ensureWs()

    this.ws.on('open', ()=>{
      this.ws.sendJsonWithJwt({
        type: 'GET_DEVICES',
        data: {
          userId
        }
      })
    })
    this.ws.on('message', ({type, data})=>{
      if(type === 'GET_DEVICES_SUCCESS_RESPONSE'){
        this.setState({
          context: {
            ...this.state.context,
            loadingDevices: false,
            ...data
          }
        })
      }
      else if(type === 'UPDATE_DEVICES'){
        this.setState({
          context: {
            ...this.state.context,
            devices: mergeArraysBy(prop('id'), [this.state.context.devices, data.devices])
          }
        })
      }
    })
  }


  render() {
    const {
      children
    } = this.props


    return (
      <Context.Provider value={this.state.context}>
        {
          children({
            ...this.state.context
          })
        }
      </Context.Provider>

    )
  }
}

export default compose(
  withWsConsumer
)(DeviceFetcher)



export function withDevicesAdjusters(Child){
  return class DevicesAdjusters extends PureComponent{

    renderChild = (context)=>
      <Child 
        {...this.props}
        {...context}
      />


    render(){
      return (
        <Context.Consumer>
          {this.renderChild}
        </Context.Consumer>
      )
    }
  }
}
