import React, {useState} from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'

const location = {
  pathname: '/app/admin/devices',
}


const styles = theme => ({
  formControl: {
    margin: 0,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

const deviceTypes = [
  {
    text: '',
    value: ''
  },
  {
    text: 'Switch',
    value: 'device_switches',
  }
]


function CreateDeviceModal({history, classes, onCreateDeviceClick}){
  

  const [state, setState] = useState({
    serial: "",
    physicalType: ""
  })

  function setSerial(e){
    setState({
      ...state,
      serial: e.target.value
    })
  }

  function setPhysicalType(e){
    setState({
      ...state,
      physicalType: e.target.value
    })
  }


  function onCancelClick(){
    history.push(location)
  }

  function handleCreateDeviceClick(){
    const {
      serial,
      physicalType
    } = state

    let errors = {}

    if(!serial){
      errors = {
        ...errors,
        serial: "Serial is required."
      }
    }
    if(!physicalType){
      errors = {
        ...errors,
        physicalType: "Device type is required."
      }
    }

    if(Object.keys(errors).length){
      return setState({
        ...state,
        errors
      }) 
    }

    onCreateDeviceClick(state)
  }

  const {
    errors: {
      serial: serialError,
      physicalType: physicalTypeError
    }={}
  } = state

  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Create Device</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To create a new device, provide a device type and a serial of 
          the chip on the device.
        </DialogContentText>
        
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="age-native-simple">Device type</InputLabel>
          <Select
            error={!!physicalTypeError}
            value={state.physicalType}
            onChange={setPhysicalType}
          >
            {deviceTypes.map(({text, value})=>
              <MenuItem key={value} value={value}>{text}</MenuItem>
            )}
          </Select>
        </FormControl>

        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Serial number"
          type="text"
          value={state.serial}
          onChange={setSerial}
          error={!!serialError}
          helperText={serialError}
          fullWidth
        />
        

      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelClick} color="primary">
          Close
        </Button>
        <Button onClick={handleCreateDeviceClick} color="primary">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}


export default withStyles(styles)(CreateDeviceModal)

