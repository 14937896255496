import React, { PureComponent } from 'react'
import Button from '@material-ui/core/Button'
import WifiIcon from '@material-ui/icons/Wifi'
import withWizard from 'library/withWizard'
import {createSelector} from 'reselect'
import {compose} from 'ramda'
import { withRouter } from 'react-router-dom'
import RouteLink from 'components/Generic/RouteLink'
import withDeviceDirectConnectFlow from './providers/withDeviceDirectConnectFlow'
import withDeviceSecret from './providers/withDeviceSecret'
import withNewWifiCreds from './providers/withNewWifiCreds'
import withUserConsumer from 'domains/Shared/UserContext/withUserConsumer'
const btnProps = {
  variant: "contained",
  color: "primary"
}

class DeviceSetup extends PureComponent {


  render() {
    const {
      //hasDevices,
      renderStep,
      renderPrevStepData,
      renderNextStepData,
      attemptDirectConnect,
      cancelDirectConnect,
      isConnectedToDevice,
      onFieldChange,
      wifiName,
      wifiPassword,
      onSyncToDeviceSubmit,
      isSyncingDataToDevice,
      attemptingConnection,
      srl
    } = this.props

    const prevStepPathData = renderPrevStepData()
    const nextStepPathData = renderNextStepData()

    return (
      <div className='page page-tight'>
        <h1 className='main-header main-header-large-bottom'>
          <span className='main-header-text'>
            New device setup
          </span>
          <span className='main-header-button-outer'>
            <WifiIcon />
          </span>
        </h1>
        <div className='wizard-step-outer'>
          {renderStep({
            srl,
            attemptDirectConnect, 
            cancelDirectConnect, 
            isConnectedToDevice,
            onFieldChange,
            wifiName,
            wifiPassword,
            onSyncToDeviceSubmit,
            isSyncingDataToDevice,
            attemptingConnection
          })}
        </div>
        <div className='step-btns-outer'>
          {nextStepPathData &&
            <div className='step-btn-next-outer'>
              <RouteLink to={nextStepPathData} component={Button} componentProps={btnProps}>
                Next
              </RouteLink>
            </div>
          }
          {prevStepPathData &&
            <div className='step-btn-prev-outer'>
              <RouteLink to={prevStepPathData} component={Button} componentProps={btnProps}>
                Prev
              </RouteLink>
            </div>
          }
        </div>
      </div>
    )
  }
}



export default compose(
  withRouter,
  withUserConsumer,
  withNewWifiCreds,
  withDeviceSecret,
  withDeviceDirectConnectFlow,
  withWizard({
    makeGetAsyncStepLogic: ()=>
      createSelector(
        ({match: {params: {step}}})=>step,
        (step)=>{
          return {
            key: step,
            fetch: ()=>
              import(`domains/DevicePortal/domains/DeviceSetup/asyncWizard/${step}/logic/index.js`)
          }
        }

      )
    ,
    makeGetAsyncStepComponent: ()=>
      createSelector(
        ({match: {params: {step}}})=>step,
        (step)=>{
          return {
            key: step,
            fetch: ()=>
              import(`domains/DevicePortal/domains/DeviceSetup/asyncWizard/${step}/component/index.js`)
          }
        }
      )
  })
)(DeviceSetup)
