import {update, findIndex, propEq} from 'ramda'


export default function updateMergeFlow(id, updateData, items){
    const idx = findIndex(propEq('id', updateData.id), items)
    const oldItem = items[idx]
    const updatedItem = {
      ...oldItem,
      ...updateData
    }
    return {
      updatedItem,
      items: update(idx, updatedItem, items)
    }
}