import React, {useMemo} from 'react'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import {isNil} from 'ramda'
import './index.css'



const tppOptions = [
  10,
  20,
  30,
  40,
  50
]


export default function Pagination(props){
  const {
    pageIdx, 
    totalPerPage,
    totalItems,
    onPaginationChange,
    totalPerPageOptions=tppOptions
  } = props

  const noTotal = isNil(totalItems)

  const selectOptions = useMemo(()=>{
    return totalPerPageOptions.map((item)=>{
      return {
        text: item,
        value: item
      }
    })
  }, [totalPerPageOptions])

  function handlePageTotalChange({target: {value}}){
    onPaginationChange({pageIdx: 0, totalPerPage: value})
  }

  const createHandlePageIdxChange = (inc)=>()=>{
    let nextIdx = inc + pageIdx

    if(nextIdx < 0 || (nextIdx * totalPerPage) >= totalItems){
      console.log("not inc'ing, out of range", nextIdx)
      return
    }

    onPaginationChange({totalPerPage, pageIdx: nextIdx})
  }


  return (
    <div className='outer-pagination'>
      <div className='op-item op-left'>
        <Button disabled={noTotal} onClick={createHandlePageIdxChange(-1)} variant="contained" color="primary" type='button'>
          Prev
        </Button>
      </div>
      <div className='op-item op-middle'>
        <Select
          disabled={noTotal}
          value={totalPerPage || totalPerPageOptions[0]}
          onChange={handlePageTotalChange}
        >
          {selectOptions.map(({text, value})=>
            <MenuItem key={value} value={value}>{text}</MenuItem>
          )}
        </Select>
      </div>
      <div className='op-item op-right'>
        <Button disabled={noTotal} onClick={createHandlePageIdxChange(1)} variant="contained" color="primary" type='button'>
          Next
        </Button>
      </div>
    </div>
  )
}