import React, { PureComponent } from 'react'
// import Button from '@material-ui/core/Button'
// import TextField from '@material-ui/core/TextField'
// import {postRegister} from 'domains/Marketing/Shared/utils/fetchHelpers'
import withUserConsumer from 'domains/Shared/UserContext/withUserConsumer'
// import parseJwtFromAuthHeader from 'domains/Marketing/domains/SignIn/utils/parseJwtFromAuthHeader'
import { withRouter } from "react-router-dom"
import {compose} from 'ramda'
import "./index.css"

class Register extends PureComponent {

  render() {
    return (
      <div className='page'>
        <div className='card main-card'>
          <h1 className='main-header'>
            Sign up
          </h1>
          <div>
            <div className='card-note-xl'>
              Swindly is still in beta.
            </div>
            <div className='card-note-md'>
              Feel free to find and chat with the CEO of Swindly.com on Twitter, <b>@swindlydotcom.</b>
            </div>
          </div>
        </div>
      </div>
    )
  }
}




// class Register extends PureComponent {
//   state = {
//     email: '',
//     password: ''
//   }

//   onSubmit = async (e)=>{
//     e.preventDefault()
//     const {
//       email,
//       password,
//       loading,
//     } = this.state
//     const {
//       storeJwt,
//       history
//     } = this.props

//     if(loading){
//       return
//     }
//     try{
//       const res = await postRegister({email, password})
//       const jwt = parseJwtFromAuthHeader(res)
//       await storeJwt(jwt)
//       history.push("/app")
//     }
//     catch(e){
//       console.log('error!', e)
//     }
//   }

//   onChange = (key)=>{
//     this.funcCache = this.funcCache || {}

//     if(!this.funcCache[key]){
//       this.funcCache[key] = (e)=>
//         this.setState({[key]: e.target.value})
//     }
    
//     return this.funcCache[key]
//   }

//   render() {
//     const {
//       email,
//       password,
//       loading
//     } = this.state

//     return (
//       <div className='page'>
//         <div className='card main-card'>
//           <h1 className='main-header'>
//             Sign up
//           </h1>
//           <form onSubmit={this.onSubmit}>
//             <div
//               className='input-wrapper'
//               >
//               <TextField
//                 variant="outlined"
//                 label="Email"
//                 value={email}
//                 margin="dense"
//                 onChange={this.onChange('email')}
//               />
//             </div>
//             <div
//               className='input-wrapper'
//               >
//               <TextField
//                 variant="outlined"
//                 label="Password"
//                 value={password}
//                 margin="dense"
//                 type='password'
//                 onChange={this.onChange('password')}
//               />
//             </div>
//             {loading ?
//               <span>
//                 loading...
//               </span>
//             :
//               <Button variant="contained" color="primary" type='submit'>
//                 Sign up
//               </Button>
//             }
            
//           </form>
//         </div>
//       </div>
//     )
//   }
// }

export default compose(
  withUserConsumer,
  withRouter,
)(Register)
