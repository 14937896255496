import React, { PureComponent } from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DeviceHubIcon from '@material-ui/icons/DeviceHub'
import AddDeviceIcon from '@material-ui/icons/AddCircleOutline'
import ListDevicesIcon from '@material-ui/icons/DevicesOther'
import {compose} from 'ramda'
import withUserConsumer from 'domains/Shared/UserContext/withUserConsumer'
import RouteLink from 'components/Generic/RouteLink'
import {path} from 'ramda'

const drawerWidth = 240


const listItemProps = {
  button: true
}


const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    paddingTop: '64px',
    overflow: 'auto'
  },
  grow: {
    flexGrow: 1,
  }
})

class BaseLayout extends PureComponent {
  state = {
    open: false,
  }

  handleDrawerOpen = () => {
    this.setState({ open: true })
  }

  handleDrawerClose = () => {
    this.setState({ open: false })
  }

  logOut 

  render() {
    const { 
      classes, 
      theme,
      userLoading,
      removeUserDataAndJwt,
    } = this.props

    const isAdmin = path(['user', 'isAdmin'], this.props)

    return (
      <div className={classes.root}>
        <AppBar
          position="absolute"
          className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
        >
          <Toolbar disableGutters={!this.state.open}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(classes.menuButton, this.state.open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="title" color="inherit" noWrap>
              Swindly
            </Typography>
            <div className={classes.grow} />
            <Button className={classes.buttonRight} onClick={removeUserDataAndJwt} color="inherit">SIGN OUT</Button>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
          }}
          open={this.state.open}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={this.handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>
          <Divider />
          <List>
            <RouteLink 
              component={ListItem} 
              componentProps={listItemProps}
              to='/app'
              >
              <ListItemIcon>
                <DeviceHubIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </RouteLink>
            <RouteLink 
              component={ListItem} 
              componentProps={listItemProps}
              prependNestedPath 
              to='/device-setup/start'
              >
              <ListItemIcon>
                <AddDeviceIcon />
              </ListItemIcon>
              <ListItemText primary="Add a device" />
            </RouteLink>
            {isAdmin &&
              <RouteLink 
                component={ListItem} 
                componentProps={listItemProps}
                prependNestedPath 
                to='/admin/devices'
                >
                <ListItemIcon>
                  <ListDevicesIcon />
                </ListItemIcon>
                <ListItemText primary="show all devices" />
              </RouteLink>
            }
          </List>
        </Drawer>
        <main className={classes.content}>
          {userLoading ? 
            <Typography>
              loading jwt and user...
            </Typography>
          :
            this.props.render()
          }

        </main>
      </div>
    )
  }
}


export default compose(
  withUserConsumer,
  withStyles(styles, { withTheme: true })
)(BaseLayout)



