import React, { PureComponent } from 'react'
import HeaderBar from './components/HeaderBar'

class BaseLayout extends PureComponent {
  render() {
    return (
      <div>
        <HeaderBar/>
        {this.props.children}
      </div>
    )
  }
}

export default BaseLayout
