import React, { PureComponent } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import {postLogin} from 'domains/Marketing/Shared/utils/fetchHelpers'
import withUserConsumer from 'domains/Shared/UserContext/withUserConsumer'
import parseJwtFromAuthHeader from './utils/parseJwtFromAuthHeader'
import { withRouter } from "react-router-dom"
import {compose} from 'ramda'

class SignIn extends PureComponent {
  state = {
    email: '',
    password: ''
  }

  onSubmit = async (e)=>{
    e.preventDefault()
    const {
      email,
      password,
      loading,
    } = this.state
    const {
      storeJwt,
      history
    } = this.props

    if(loading){
      return
    }
    try{
      const res = await postLogin({email, password})
      const jwt = parseJwtFromAuthHeader(res)
      await storeJwt(jwt)
      history.push("/app")
    }
    catch(error){
      this.setState({
        apiError: error.jsonResponse
      })
    }
  }

  onChange = (key)=>{
    this.funcCache = this.funcCache || {}

    if(!this.funcCache[key]){
      this.funcCache[key] = (e)=>
        this.setState({[key]: e.target.value})
    }
    
    return this.funcCache[key]
  }

  render() {
    const {
      email,
      password,
      loading,
      apiError: {
        error: apiErrorMessage,
        errorFields: {
          email: emailError,
          password: passwordError
        }={}
      }={}
    } = this.state


    let apiUserNotFound = /user not found/.test(apiErrorMessage)



    return (
      <div className='page'>
        <div className='card main-card'>
          <h1 className='main-header'>
            Sign in
          </h1>
          {
            apiUserNotFound &&
            <div className="q-alert q-alert-red">
              Incorrect email or password.
            </div>
          }


          <form onSubmit={this.onSubmit}>
            <div
              className='input-wrapper'
              >
              <TextField
                error={!!emailError}
                helperText={emailError}
                variant="outlined"
                label="Email"
                value={email}
                margin="dense"
                onChange={this.onChange('email')}
              />
            </div>
            <div
              className='input-wrapper'
              >
              <TextField
                error={!!passwordError}
                helperText={passwordError}
                variant="outlined"
                label="Password"
                value={password}
                margin="dense"
                onChange={this.onChange('password')}
                type='password'
              />
            </div>
            {loading ?
              <span>
                loading...
              </span>
            :
              <Button variant="contained" color="primary" type='submit'>
                Sign in
              </Button>
            }
            
          </form>
        </div>
      </div>
    )
  }
}

export default compose(
  withUserConsumer,
  withRouter,
)(SignIn)
