import React from 'react'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'

export default function AddButton(props){
  const opts = {
    ...props,
    color: "primary", 
    'aria-label': "Add"
  }
  return (
    <Fab {...opts}>
      <AddIcon />
    </Fab>
  )
}