import React, { PureComponent } from 'react'
import {  Route, Redirect } from 'react-router-dom'
import withUserConsumer from 'domains/Shared/UserContext/withUserConsumer'
// import { matchPath } from 'react-router'
import {compose} from 'ramda'

class SignedInPublicPathRedirector extends PureComponent {
  renderLoading = ()=>
    <span>
      loading...
    </span>



  renderRedirectToPrivatePage(){
    const {
      location
    } = this.props

    return (
      <Redirect
        to={{
          pathname: "/app",
          state: { from: location }
        }}
      />
    )
  }

  isProhibitedPathWhenAlreadyAuthed(){
    const {
      location
    } = this.props

    // only redirect if it's a page that you shouldn't
    // be able to view if you are already signed in
    return /^\/(sign-in|register)/.test(location.pathname)
  }



  handleRouteRender = ()=>{
    const {
      jwt,
      userLoading,
      render
    } = this.props

    if(userLoading){
      return this.renderLoading()
    }
    // if we have the jwt, we don't want them to view
    // this given public path (useful for not showing
    // sign-in page after signed in)
    return jwt && this.isProhibitedPathWhenAlreadyAuthed() ? this.renderRedirectToPrivatePage() : render()
  }

  render() {
    const { 
      component: Component,
      ...rest 
    } = this.props

    return (
      <Route
        {...rest}
        render={this.handleRouteRender}
      />
    )
  }
}




export default compose(
  withUserConsumer
)(SignedInPublicPathRedirector)
