import React, {PureComponent} from 'react'
import {Consumer} from 'domains/Shared/WsContext/Context'


export default function withWsConsumer(Child){
  return class WsConsumer extends PureComponent{

    renderChild = (context)=>
      <Child 
        {...this.props}
        {...context}
      />


    render(){
      return (
        <Consumer>
          {this.renderChild}
        </Consumer>
      )
    }
  }
}