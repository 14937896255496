import React, { PureComponent } from 'react'
import delay from 'library/delay'
import {
  storeSerial,
  storeSecret
} from 'domains/DevicePortal/Shared/utils/fetchHelpers'

export default function withDeviceDirectConnectFlow(Child){
  return class DeviceDirectConnectFlow extends PureComponent {
    constructor(props){
      super(props)
      this.state = {}
    }



    attemptDirectConnect = ()=>{
      this.setState({attemptingConnection: true}, this.pingDeviceFlow)
    }

    pingDeviceFlow = async ()=>{
      if(this.state.attemptingConnection){
        try{
          await this.pingDevice()
          await this.asyncSetState({isConnectedToDevice: true})
          await delay(1000)
          this.pingDeviceFlow()
        }
        catch(e){
          await this.asyncSetState({isConnectedToDevice: false})
          await delay(1000)
          this.pingDeviceFlow()
        }
      }
    }

    asyncSetState = (data)=>{
      return new Promise((resolve)=>{
        this.setState(data, ()=>{
          resolve()
        })
      })
    }

    cancelDirectConnect = ()=>{
      this.setState({
        attemptingConnection: false, 
        isConnectedToDevice: false
      })
    }



    onSyncToDeviceSubmit = (e)=>{
      window.addEventListener("message", this.receiveMessage, false)
      if(!this.tab || this.tab.closed){
        this.tab = window.open('http://192.168.4.1/sync', '_blank')
      }
           


      this.setState({isSyncingDataToDevice: true})



    }



    receiveMessage = (event)=>{
      // Check for valid sender domain
      if(!/^https?:\/\/192\.168\.4\.1/.test(event.origin)){
        return
      }
      const {deviceSecret, wifiName, wifiPassword} = this.props

      if(event.data.type === 'READY'){
        this.tab.postMessage({
          type: 'CREDS', 
          payload: {
            scrt: deviceSecret,
            u: wifiName,
            pswrd: wifiPassword
          }
        }, 'http://192.168.4.1')
      }
      else if(event.data.type === 'SET_SERIAL'){
        const srl = event.data.payload.srl
        this.setState({
          srl
        })
      }
      else if(event.data.type === 'DONE'){
        debugger
        console.log('received done!')
        window.removeEventListener("message", this.receiveMessage)
        this.setState({
          storedSecretSuccess: true,
          storedWifiCredsSuccess: true,
          receivedSerialSuccess: true,
          attemptingConnection: false,
          isConnectedToDevice: false
        })
        this.tab.close()
        this.tab = null
      }
    }


    startSync = async ()=>{



      // await this.postToDevice({
      //   scrt: deviceSecret,
      //   u: wifiName,
      //   pswrd: wifiPassword
      // })
      // const res = await this.fetchSerial() 

      // this.setState({
      //   srl: res.jsonResponse.srl,
      //   storedSecretSuccess: true,
      //   storedWifiCredsSuccess: true,
      //   receivedSerialSuccess: true,
      //   attemptingConnection: false,
      //   isConnectedToDevice: false
      // })
    }


    // postToDevice = (data)=>{
    //   return fetcher(`http://192.168.4.1/store-setup`, createBuildablePipeline(
    //     toFormData,
    //     toPost
    //   )({body: JSON.stringify(data)}))
    // }

    // fetchSerial = (data)=>{
    //   return fetcher(`http://192.168.4.1/serial`, prepareJwtGetJsonPipeline(data))
    // }

    // pingDevice = ()=>{
    //   console.log('ping')
    //   return fetcher(`http://192.168.4.1/ping`, prepareJwtGetJsonPipeline())
    // }
    componentWillUnmount(){
      window.removeEventListener("message", this.receiveMessage)
    }


    componentDidUpdate(preProps){
      const {
       isSyncingDataToDevice,
       storedSecretSuccess,
       storedWifiCredsSuccess,
       receivedSerialSuccess,
      } = this.state
      if(isSyncingDataToDevice && storedSecretSuccess && storedWifiCredsSuccess && receivedSerialSuccess){
        console.log('success!')
        this.setState({isSyncingDataToDevice: false})
        this.props.history.push({pathname: '/app/device-setup/reconnect-internet'})
        this.attemptStoreAttempts = 0
        this.attemptStoreToBackendFlow()
      }
    }


    attemptStoreToBackendFlow = async ()=>{
      this.attemptStoreAttempts += 1
      try{
        const {
          srl
        } = this.state
        const {
          jwt,
          deviceSecret
        } = this.props


        if(srl && jwt){

          const {
            jsonResponse: {
              device: {
                id: deviceId
              }
            }
          } = await storeSerial(
            {
              srl
            }
          , jwt)


          await storeSecret(
            {
              id: deviceId,
              scrt: deviceSecret
            }
          , jwt)
        }
      }
      catch(e){
        if(this.attemptStoreAttempts <= 20){
          setTimeout(()=>{
            this.attemptStoreToBackendFlow()
          }, 1000)
        }
      }

    }



    render(){
      return (
        <Child
          {...this.props}
          srl={this.state.srl}
          onSyncToDeviceSubmit={this.onSyncToDeviceSubmit}
          attemptDirectConnect={this.attemptDirectConnect}
          cancelDirectConnect={this.cancelDirectConnect}
          isConnectedToDevice={this.state.isConnectedToDevice}
          attemptingConnection={this.state.attemptingConnection}
          isSyncingDataToDevice={this.state.isSyncingDataToDevice}
          />  
      )
    }
  }
}