import {indexBy} from 'ramda'

export default function mergeArraysBy(func, arrs){
  const targetArr = arrs[0]
  const mergableArrs = arrs.slice(1)
  const indexedArrs = mergableArrs.map(indexBy(func))

  return targetArr.map((item)=>{
    const k = func(item)
    const res = indexedArrs.reduce((acc, indexObj)=>{
      const mergable = indexObj[k]
      if(mergable){
        return Object.assign({}, acc, item, mergable)
      }
      else{
        return Object.assign({}, item, acc)
      }
    }, {})
    return res
  })
}