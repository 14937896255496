import React, { PureComponent } from 'react'

class Home extends PureComponent {
  render() {
    return (
      <div>
        Home page
      </div>
    )
  }
}

export default Home
